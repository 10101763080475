import React, { useEffect, useRef, useState } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    FormControlLabel,
    Checkbox,
    TextField,
} from "@mui/material";

interface EditProjectModalProps {
    open: boolean;
    onClose: () => void;
    project: Partial<Project> | null;
    onSubmit: (updatedProject: {
        location?: string;
        latitude?: number;
        longitude?: number;
        takesAssistance?: boolean;
    }) => void;
}

type Prediction = google.maps.places.AutocompletePrediction;

const EditProjectModal: React.FC<EditProjectModalProps> = ({
    open,
    onClose,
    project,
    onSubmit,
}) => {
    const [latitude, setLatitude] = useState<number | undefined>(project?.latitude);
    const [longitude, setLongitude] = useState<number | undefined>(project?.longitude);
    const [takesAssistance, setTakesAssistance] = useState<boolean>(project?.takesAssistance || false);
    const [input, setInput] = useState<string>("");
    const [predictions, setPredictions] = useState<Prediction[]>([]);
    const autocompleteService = useRef<google.maps.places.AutocompleteService | null>(null);
    const placesServiceRef = useRef<google.maps.places.PlacesService | null>(null);
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (window.google && window.google.maps) {
            const map = new window.google.maps.Map(document.createElement("div"));
            placesServiceRef.current = new window.google.maps.places.PlacesService(map);
        }
        if (window.google && !autocompleteService.current) {
            autocompleteService.current = new window.google.maps.places.AutocompleteService();
        }
        if (window.google && !placesServiceRef.current && inputRef.current) {
            placesServiceRef.current = new window.google.maps.places.PlacesService(inputRef.current);
        }
    }, []);

    useEffect(() => {
        if (project) {
            setLatitude(project.latitude);
            setLongitude(project.longitude);
            setTakesAssistance(project.takesAssistance || false);
        } else {
            setLatitude(undefined);
            setLongitude(undefined);
            setTakesAssistance(false);
        }
        setInput("");
    }, [project]);

    const handleAutoCompletePlaceSelected = (placeId: string) => {
        if (placesServiceRef.current) {
            placesServiceRef.current.getDetails(
                { placeId, fields: ["geometry", "formatted_address"] },
                (place, status) => {
                    if (status === window.google.maps.places.PlacesServiceStatus.OK && place) {
                        setLatitude(place.geometry?.location?.lat());
                        setLongitude(place.geometry?.location?.lng());
                        setInput(place.formatted_address || "");
                        setPredictions([]);
                    }
                }
            );
        }
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setInput(value);

        if (value && autocompleteService.current) {
            autocompleteService.current.getPlacePredictions(
                {
                    input: value,
                    componentRestrictions: { country: "cl" },
                },
                (results, status) => {
                    if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                        setPredictions(results || []);
                    } else {
                        setPredictions([]);
                    }
                }
            );
        } else {
            setPredictions([]);
        }
    };

    const handleSubmit = () => {
        if (!latitude || !longitude) {
            setTakesAssistance(false)
        }

        const updatedLocation = (!input || input === "") ? undefined : input
        onSubmit({ location: updatedLocation, latitude, longitude, takesAssistance: ((!latitude || !longitude) ? false : takesAssistance) });

        onClose();
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Editar Proyecto</DialogTitle>
            <DialogContent>
                <div>Direccion Actual: </div>
                <div>{project?.location}</div>
                <TextField
                    label="Dirección Precisa"
                    value={input}
                    onChange={handleInputChange}
                    fullWidth
                    inputRef={inputRef}
                    placeholder="Busca una dirección"
                    margin="normal"
                />
                <div style={{ position: "relative" }}>
                    {predictions.map((prediction) => (
                        <div
                            key={prediction.place_id}
                            onClick={() => handleAutoCompletePlaceSelected(prediction.place_id)}
                            style={{
                                cursor: "pointer",
                                padding: "8px",
                                borderBottom: "1px solid #ddd",
                                background: "#fff",
                            }}
                        >
                            {prediction.description}
                        </div>
                    ))}
                </div>

                <div className="border mb-2 px-3 py-2">
                    <p>Latitud: {latitude}</p>
                </div>

                <div className="border mb-2 px-3 py-2">
                    <p>Longitud: {longitude}</p>
                </div>

                <FormControlLabel
                    control={
                        <Checkbox
                            checked={takesAssistance}
                            onChange={(e) => setTakesAssistance(e.target.checked)}
                        />
                    }
                    disabled={!longitude || !latitude}
                    label="Asistencia"
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancelar</Button>
                <Button variant="contained" disabled={!input && !project?.location} onClick={handleSubmit}>
                    Guardar
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default EditProjectModal;
