import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { convertToStringDate } from './dateConverter';


export const generateAssistanceExcel = async (project, AssistanceList, employees, initDate, endDate) => {
    // Crear un nuevo libro de trabajo
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(`Listado de asistencia ${project.projectName}`);

    const columns = [
        { header: 'Empleado', key: 'employee', width: 20 },
    ];

    // Definir qué días se tomará asistencia
    endDate.setDate(endDate.getDate() + 1);
    const days = Math.floor((endDate.getTime() - initDate.getTime()) / (1000 * 60 * 60 * 24));

    // Agregar una columna de entrada y una de salida por dia de la obra
    for (let i = 0; i < days; i++) {
        const actualDate = new Date(initDate)
        actualDate.setDate(initDate.getDate() + i);
        actualDate.setHours(4, 0, 0, 0);
        const formattedDate = actualDate.toLocaleDateString('es-ES');

        columns.push(
            { header: `Entrada ${formattedDate}`, key: `inAssistance${i}`, width: 20 },
            { header: `Salida ${formattedDate}`, key: `outAssistance${i}`, width: 20 },
        );
    }
    worksheet.columns = columns;



    employees.forEach((employee) => {
        const myAssistance = AssistanceList.filter((assistance) => {return assistance.employeeUsername === employee.username})

        const inValues = []
        const outValues = []
        for (let i = 0; i < days; i++) {
            const inMark = myAssistance.find((assistance) => {
                const actualDate = new Date(initDate)
                const finishDate = new Date(initDate)
                actualDate.setHours(4, 0, 0, 0);
                finishDate.setHours(4, 0, 0, 0);
                actualDate.setDate(actualDate.getDate() + i)
                finishDate.setDate(finishDate.getDate() + i + 1)

                return new Date(assistance.date) > actualDate && new Date(assistance.date) < finishDate && assistance.type === "entrada"
            })

            const outMark = myAssistance.find((assistance) => {
                const actualDate = new Date(initDate)
                const finishDate = new Date(initDate)
                actualDate.setHours(4, 0, 0, 0);
                finishDate.setHours(4, 0, 0, 0);
                actualDate.setDate(actualDate.getDate() + i)
                finishDate.setDate(finishDate.getDate() + i + 1)

                return new Date(assistance.date) > actualDate && new Date(assistance.date) < finishDate && assistance.type === "salida"
            })

            inValues.push(inMark ? convertToStringDate(inMark.date).split(" ")[1].slice(0, 8) : "Sin marca")
            outValues.push(outMark ? convertToStringDate(outMark.date).split(" ")[1].slice(0, 8) : "Sin marca")
        }

        
        const rowData = { employee: employee.username };
        for (let i = 0; i < days; i++){
            rowData[`inAssistance${i}`] = inValues[i]
            rowData[`outAssistance${i}`] = outValues[i]
        }

        const row = worksheet.addRow(rowData);


        for (let i = 0; i < days; i++) {
            const inCell = row.getCell(2*i+2);
            const outCell = row.getCell(2*i+3);
            
            inValues[i] === 'Sin marca' ? 
            inCell.style.fill = {type: 'pattern', pattern: "solid", fgColor: { argb: 'FFDDDD' }} :
            inCell.style.fill = {type: 'pattern', pattern: "solid", fgColor: { argb: 'CCFFCC' }};

            outValues[i] === 'Sin marca' ? 
            outCell.style.fill = {type: 'pattern', pattern: "solid", fgColor: { argb: 'FFDDDD' }} :
            outCell.style.fill = {type: 'pattern', pattern: "solid", fgColor: { argb: 'CCFFCC' }};
        }

    });


    // Generar el archivo Excel y guardarlo
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(blob, `Asistencia-${project.projectName}.xlsx`);
};



export const generateEmployeeAssistanceExcel = async (projects, AssistanceList, employee, initDate, endDate) => {
    // Crear un nuevo libro de trabajo
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(`Listado de asistencia ${employee.username}`);

    const columns = [
        { header: 'Proyecto', key: 'project', width: 20 },
    ];

    // Definir qué días se tomará asistencia, vienen ordenadas por fecha asi que la primera asistencia es la más antigua
    endDate.setDate(endDate.getDate() + 1);
    const days = Math.floor((endDate.getTime() - initDate.getTime()) / (1000 * 60 * 60 * 24));

    // Agregar una columna de entrada y una de salida por dia de la obra
    for (let i = 0; i < days; i++) {
        const actualDate = new Date(initDate)
        actualDate.setDate(actualDate.getDate() + i);
        actualDate.setHours(4, 0, 0, 0);
        const formattedDate = actualDate.toLocaleDateString('es-ES');

        columns.push(
            { header: `Entrada ${formattedDate}`, key: `inAssistance${i}`, width: 20 },
            { header: `Salida ${formattedDate}`, key: `outAssistance${i}`, width: 20 },
        );
    }

    worksheet.columns = columns;



    projects.forEach((project) => {
        const projectAssistance = AssistanceList.filter((assistance) => {
            return assistance.projectNumber === project.projectNumber})
        
        const inValues = []
        const outValues = []
        for (let i = 0; i < days; i++) {
            const inMark = projectAssistance.find((assistance) => {
                const actualDate = new Date(initDate)
                const finishDate = new Date(initDate)
                actualDate.setHours(4, 0, 0, 0);
                finishDate.setHours(4, 0, 0, 0);
                actualDate.setDate(actualDate.getDate() + i)
                finishDate.setDate(finishDate.getDate() + i + 1)

                return new Date(assistance.date) > actualDate && new Date(assistance.date) < finishDate && assistance.type === "entrada"
            })

            const outMark = projectAssistance.find((assistance) => {
                const actualDate = new Date(initDate)
                const finishDate = new Date(initDate)
                actualDate.setHours(4, 0, 0, 0);
                finishDate.setHours(4, 0, 0, 0);
                actualDate.setDate(actualDate.getDate() + i)
                finishDate.setDate(finishDate.getDate() + i + 1)

                return new Date(assistance.date) > actualDate && new Date(assistance.date) < finishDate && assistance.type === "salida"
            })

            inValues.push(inMark ? convertToStringDate(inMark.date).split(" ")[1].slice(0, 8) : "Sin marca")
            outValues.push(outMark ? convertToStringDate(outMark.date).split(" ")[1].slice(0, 8) : "Sin marca")
        }
        
        const rowData = { project: project.projectName };
        for (let i = 0; i < inValues.length; i++){
            rowData[`inAssistance${i}`] = inValues[i]
            rowData[`outAssistance${i}`] = outValues[i]
        }
        const row = worksheet.addRow(rowData);


        for (let i = 0; i < days; i++) {
            const inCell = row.getCell(2*i+2);
            const outCell = row.getCell(2*i+3);
            
            inValues[i] === 'Sin marca' ? 
            inCell.style.fill = {type: 'pattern', pattern: "solid", fgColor: { argb: 'FFDDDD' }} :
            inCell.style.fill = {type: 'pattern', pattern: "solid", fgColor: { argb: 'CCFFCC' }};

            outValues[i] === 'Sin marca' ? 
            outCell.style.fill = {type: 'pattern', pattern: "solid", fgColor: { argb: 'FFDDDD' }} :
            outCell.style.fill = {type: 'pattern', pattern: "solid", fgColor: { argb: 'CCFFCC' }};
        }

    });


    // Generar el archivo Excel y guardarlo
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(blob, `Asistencia-${employee.username}.xlsx`);
};
