import React from 'react'
import { useState } from 'react'
import { Box, Button, Tab, Tabs } from '@mui/material'
import ProjectsTabView from './ProjectsTabView';
import { generateOrderXLS } from '../../../services/proyectService';

const MainView = () => {
    const [tab, setTab] = useState("both");
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        localStorage.removeItem("projectsFilter");
        setTab(newValue);
    };
    return (
        <div className="h-full flex flex-col overflow-auto">
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs value={tab} onChange={handleChange} variant="scrollable">
                    <Tab label="Proyectos" value={"project"} />
                    <Tab label="Obras" value={"construction"} />
                    <Tab label="Ambos" value={"both"} />
                </Tabs>
            </Box>
            <div className="flex-1 overflow-auto">
                <Button
                    variant="contained"
                    onClick={generateOrderXLS}>Descargar historico</Button>
                <ProjectsTabView type={tab}></ProjectsTabView>
            </div>
        </div>
    )
}

export default MainView