import axios from "./client";
import FileDownload from "js-file-download";

const API_URL = process.env.REACT_APP_API_URL;

export const createProject = async (project: Partial<Project>) => {
    try {
        await axios.post<Project>(`${API_URL}projects`, project);
        return true;
    }
    catch (error) {
        alert((error as any).response.data)
        return false;
    }
}

export const getProjects = async (type: string, approvedStatus?: string, hasLatAndLong?: boolean) => {
    try {
        let params: { type?: string; approvedStatus?: string; hasLatAndLong?: boolean; } = {};
        if (type !== 'both') {
            params.type = type;
        }
        if (!!approvedStatus) {
            params.approvedStatus = approvedStatus;
        }
        if (!!hasLatAndLong) {
            params.hasLatAndLong = hasLatAndLong;
        }

        const { data } = await axios.get<Project[]>(`${API_URL}projects/`, { params });
        return data;
    } catch (error) {
        console.error('Error fetching projects:', error);
        alert('Error encontrando lista de proyectos')
        return [];
    }
}

export const updateProject = async (id: string, project: Partial<Project>) => {
    try {
        await axios.patch<Project>(`${API_URL}projects/${id}`, project);
        return true;
    }
    catch (error) {
        return false;
    }
}

export const getProjectById = async (id: string) => {
    try {
        const { data } = await axios.get<Project>(`${API_URL}projects/${id}`);
        return data;
    } catch (error) {
        console.error('Error fetching project:', error);
        return null;
    }
}

export const getPaymentForms = async () => {
    try {
        const { data } = await axios.get<PaymentFormUnwind[]>(`${API_URL}projects/paymentForms`);
        return data;
    } catch (error) {
        console.error('Error fetching payment forms:', error);
        return [];
    }
}

export const assignInvoiceToPaymentForm = async (projectId: string, paymentKey: string, invoiceId: string, paymentPercentage: number, type: string, additionalName: string, invoiceNumber: string) => {
    try {
        await axios.patch(`${API_URL}projects/paymentForms/${projectId}`, { paymentKey, invoiceId, paymentPercentage, type, additionalName, invoiceNumber });
        return true;
    }
    catch (error) {
        return false;
    }
}

export const uploadFileToProject = async (projectId: string, file: File) => {
    try {
        const formData = new FormData();
        formData.append('file', file);
        const response = await axios.post(`${API_URL}projects/upload/${projectId}`, formData);
        return {
            success: true,
            data: response.data
        }
    }
    catch (error) {
        console.log(error)
        return {
            success: false,
        };
    }
}

export const downloadFile = async (fileName: string) => {
    try {
    const response = await axios.post(`${API_URL}projects/download`, {
            key: fileName
    });
    if(response.status === 200) {
        const url = response.data.data;
        window.open(url, "_blank");
    }
}
catch (error) {
    console.error(error);
}
}

export const generateOrderXLS = async () => {
    const res = await axios.get(API_URL + `projects/historical`, {
      responseType: "blob",
      headers: {
        "content-type": "application/vnd.ms-excel;charset=UTF-8",
      },
    });
    FileDownload(res.data, 'Historico.xlsx');
  };